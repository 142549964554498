import { asLink } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import { useEffect } from 'react';
import { Carousel } from 'ui/components/Carousel';
import { CommonButton } from 'ui/components/CommonButton';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { SVGLibrary } from 'ui/svgs/library';
import clsx from 'clsx';
import { SharedSlice } from '@prismicio/client';
import { HeroSliceDarkOnlyHomePageWithLogo } from 'prismic/prismicio-types';

export type HeroDarkProps = SliceComponentProps<SharedSlice<'hero', HeroSliceDarkOnlyHomePageWithLogo>>;

const HeroDarkOnlyHomePageWithLogo = ({ slice }: HeroDarkProps): JSX.Element => {
  const { variation, primary, slice_type } = slice;

  const {
    background_image,
    heading,
    description,
    primary_cta_text,
    primary_cta_action,
    primary_cta_url,
    primary_cta_target,
    secondary_cta_text,
    secondary_cta_action,
    secondary_cta_target,
    secondary_cta_url,
    eyebrow_text,
    cta_description,
    text_theme,
    logos,
    column_split_size
  } = primary;
  const { title, logos: items } = (logos as any)?.data || {};

  useEffect(() => {
    const logoSlidercontainer = document.querySelector('#hero-logos-slider-container') as HTMLElement;
    const backdropContainer = document.getElementById('backgrop-logos-hero-dark') as HTMLElement;
    if (logoSlidercontainer) {
      const computeHeightForBackdrop = window.getComputedStyle(logoSlidercontainer, null).getPropertyValue('height');
      if (backdropContainer) {
        backdropContainer.style.height = `${computeHeightForBackdrop}`;
      }
    }
  }, []);

  const getButtonProps = (action: string, url: any, target: string) => {
    return {
      ...(action === 'Get Started Overlay' ? { onClick: () => null } : { href: asLink(url), target: target }),
    };
  };
  return (
    <section
      className="Hero-DarkHomeLogo wide-container [ relative overflow-hidden w-full ][ bg-cover bg-center bg-no-repeat bg-gray-0 ]"
      data-slice-type={slice_type}
      data-slice-variation={variation}
      id="hero-dark"
    >
      <PrismicNextImage
        field={background_image}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          backgroundPositionX: 'center',
        }}
        width={background_image.dimensions.width}
        height={background_image.dimensions.height}
        loading="eager"
        fetchPriority='high'
        priority
      />
      <div className="[ grid grid-cols-1 md:grid-cols-12 ][ pt-60 px-5 md:pt-[410px] lg:pt-[398px] md:px-16 md:pb-10 ]">
        <div className={clsx('[ md:col-span-9 ][ pt-8 md:pt-0 ] [ z-[10] ]', {
          'lg:col-span-6' : column_split_size === '50_50',
          'lg:col-span-8' : column_split_size === '60_40'
        })}>
          {eyebrow_text &&
            <h2 className={clsx("[ text-16 font-medium ][ mb-4 ]", {
              '[ text-gray-10 ]' : text_theme === 'dark',
              '[ text-white ]' : text_theme === 'light'
            })}>{eyebrow_text}</h2>
          }
          <h1 className={clsx("[ text-30 md:text-60 font-medium tracking-tight leading-tight ][ mb-6 ][ max-w-[560px] ]", {
              '[ text-gray-10 ]' : text_theme === 'dark',
              '[ text-white ]' : text_theme === 'light'
            })} data-testid="heading">
            {heading}
          </h1>
          <div className={clsx("[ mb-6 md:mb-14 ][ text-14 md:text-20 ]", {
            '[ text-gray-10 ]' : text_theme === 'dark',
            '[ text-white ]' : text_theme === 'light'
          })}>
            <PrismicRichText
              field={description}
              applyStyles={false}
              boldHighlight="primary"
            />
          </div>
          {primary_cta_text && (
            <CommonButton
              color="white"
              size="medium"
              {...getButtonProps(primary_cta_action, primary_cta_url, primary_cta_target)}
              className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
            >
              {primary_cta_text}
            </CommonButton>
          )}
          {secondary_cta_text && (
            <CommonButton
              appearance="outline"
              color="white"
              size="medium"
              {...getButtonProps(secondary_cta_action, secondary_cta_url, secondary_cta_target)}
              className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
            >
              {secondary_cta_text}
            </CommonButton>
          )}
          {cta_description && (
            <h2 className={clsx("[ text-14 ][ mb-4 ]", {
              '[ text-gray-10 ]' : text_theme === 'dark',
              '[ text-white ]' : text_theme === 'light'
            })}>{cta_description}</h2>
          )}
        </div>
      </div>
      {items && items.length > 0 && (
        <>
          <div id="backgrop-logos-hero-dark" className="wide-container [ absolute left-0 bottom-0 ][ w-full ][ bg-gradient-to-b from-gray-0/0 to-gray-0/90 ]"></div>
          <div className="[ relative overflow-hidden ][ w-screen max-w-9xl ][ pt-24 pb-16 ]" id="hero-logos-slider-container">
            {false &&
              <h4 className={clsx("[ text-20 ][ pl-5 mb-6 md:pl-16 md:mb-12 ]", {
                '[ text-gray-10 ]' : text_theme === 'dark',
                '[ text-white ]' : text_theme === 'light'
              })} data-testid="heading">
                {title}
              </h4>
            }
            <div className="[ pl-5 md:pl-16 ]">
              <Carousel
                slideSize={256}
                slideGap="lg"
                loop={true}
                autoPlay
                autoPlayDelay={2000}
                speed={0.2}
                withControls={false}
                withIndicators={false}
                className="[ relative overflow-hidden ]"
              >
                {(items || []).map((logo, index) => (
                  <div key={index} className="[ flex items-center justify-center ][ pr-20 ]">
                    {logo?.inline_svg ? (
                      <SVGLibrary name={logo?.inline_svg} />
                    ) : (
                      <PrismicNextImage height={40} className="[ max-w-inherit ]" field={logo?.logo} />
                    )}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default HeroDarkOnlyHomePageWithLogo;