import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";

const useHeaderData = (data) => {
  const { t } = useTranslation("header");
  const [headerData, setHeaderData] = useState(data);

  useEffect(() => {
    // If running on the client, fetch the localized data
    if (typeof window !== "undefined") {
      const translatedData = t('.', {}, { returnObjects: true });
      setHeaderData(translatedData);
    }
  }, [t]);

  // Return server-side data (initial) or localized data (client-side)
  return headerData;
};

export default useHeaderData;
