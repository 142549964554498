import { Content, asLink } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { extractVideoId } from 'ui/components/developer/Video/video.helper';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import VideoInternalSection from 'ui/sections/VideoInternalSection';
import { VideoInternal } from 'ui/components/VideoInternal';
import { asImageSrc } from '@prismicio/client';
import { SVGLibrary } from 'ui/svgs/library';
import { CommonButton } from 'ui/components/CommonButton';
import { useEffect, useState, useId } from 'react';
import clsx from 'clsx';

/**
 * Props for `VideoBlock`.
 */
export type VideoBlockProps = SliceComponentProps<Content.VideoBlockSlice>;

/**
 * Component for "VideoBlock" Slices.
 */
const VideoBlock = ({ slice }: VideoBlockProps): JSX.Element => {
  const { primary, slice_type, variation } = slice;
  const { thumbnail, video_url } = primary;
  const [width] = useWindowsSize();
  const [bg, setBg] = useState(variation === 'blockWithText' ? primary.background : null);
  const video_id = useId()

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });

    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);

  useEffect(() => {
    function handleScroll() {
      const element = document.getElementById(video_id) as HTMLDivElement;

      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (elementTop < windowHeight && elementTop > 0) {
          // Calculate the new width based on scroll position as a percentage
          const maxWidthPercent = 100; // Max width as a percentage
          const minWidthPercent = 82.32; // Min width as a percentage (calculated from 1080 / 1312 * 100)
          const scrollRange = windowHeight; // The range within the viewport
          const scrolled = Math.min(windowHeight - elementTop, scrollRange); // How much the element has been scrolled into view
          const newWidthPercent = minWidthPercent + (maxWidthPercent - minWidthPercent) * (scrolled / scrollRange);

          element.style.width = `${Math.min(newWidthPercent + 5, 100)}%`;
        } else if (elementTop >= windowHeight) {
          // Reset width if the element is above the viewport
          element.style.width = '82.32%';
        }
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  if (variation === 'blockWithText') {
    const { background, cta_url, cta_text, cta_icon, video_provider } = primary;

    return (
      <section
        data-slice-type={slice_type}
        data-slice-variation={variation}
        data-background={background}
        className={clsx(
          'wide-container color-changing-section',
          '[ transition-color ease-in-out duration-1500 ]',
          {
            '[ bg-blue-95 ]': bg === 'light_blue',
            '[ bg-gray-97 ]': bg === 'light',
            '[ bg-gray-0 ]': bg === 'dark',
          },
        )}
      >
        <div className="[ px-5 md:px-16 py-16 md:py-28 ]">
          <div
            className={clsx(
              '[ grid grid-cols-1 gap-5 lg:gap-20 ][ mb-12 md:mb-20 ]',
              {
                '[ lg:grid-cols-2 ]': primary.layout === 'left_justified_50_50',
                '[ items-center ][ text-center ]': primary.layout === 'full_width_centerd',
              },
            )}
          >
            <div
              data-testid="heading"
              className={clsx(
                '[ font-medium text-26 md:text-46 tracking-tight ]',
                {
                  '[ text-gray-97 ]': bg === 'dark',
                  '[ text-gray-0 ]': bg !== 'dark',
                },
              )}
            >
              {primary.header}
            </div>

            <div
              className={clsx(
                '[ mb-0 ][ [&_p]:text-16 [&_p]:md:text-20 ]',
                {
                  '[ [&_p]:text-gray-60 ]': bg === 'dark',
                  '[ [&_p]:text-gray-30  ]': bg !== 'dark',
                },
              )}
            >
              <PrismicNextImage field={primary.text_image} className="[ mb-5 md:mb-6 ]" loading='lazy' />
              <PrismicRichText field={primary.text} applyStyles={false} />
              {cta_text && (
                <div className="[ mt-6 ]">
                  <CommonButton
                    field={cta_url}
                    color={bg === 'dark' ? 'white' : 'black'}
                    hasIcon={cta_icon ? 'left' : undefined}
                    size='large'
                  >
                    {cta_icon && (
                      <SVGLibrary name={cta_icon} fill="currentColor" />
                    )}
                    {cta_text}
                  </CommonButton>
                </div>
              )}
            </div>
          </div>

          {primary.media_type === 'Image' ? (
            <>
              {asImageSrc(primary.image) ? (
                <div className="[ rounded-2xl ][ w-full h-full overflow-hidden ]">
                  <PrismicNextImage field={primary.image} loading='lazy' />
                </div>
              ) : (
                <SVGLibrary name={(primary.svg_inline as any) || 'Rowing'} />
              )}
            </>
          ) : (
            <div id={video_id} className="[ mx-auto ]">
              <VideoInternal
                data={{
                  video: {
                    id: extractVideoId(primary.youtube_video_id),
                    videoProvider: video_provider,
                    videoURL: asLink(video_url),
                  },
                  thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} loading='lazy' />,
                }}
                playIcon={'/img/icons/icon-play-button.svg'}
                className="[ transition-all duration-100 ease-out ][ aspect-video !w-full overflow-hidden ][ [&_img]:rounded-2xl ]"
              />
            </div>
          )}
        </div>
      </section>
    );
  }

  return (
    <VideoInternalSection
      data-slice-type={slice_type}
      data-slice-variation={variation}
      video={{ id: extractVideoId(asLink(video_url)) }}
      thumb={<PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} loading='lazy' />}
      sectionProps={{
        pad: width > ResponsiveBreakpoint ? 'none' : 'sm',
      }}
    />
  );
};

export default VideoBlock;
