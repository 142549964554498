import { FC, PropsWithChildren } from "react";
import {PrismicPreview as PrismicPreviewCore} from '@prismicio/next';
import { repositoryName } from './prismicio';

const PrismicPreviewTest: FC<PropsWithChildren> = ({children}) => {
    return (
      <PrismicPreviewCore
        repositoryName={repositoryName}
        // updatePreviewURL="/api/prismic-preview"
        // exitPreviewURL="/api/exit-prismic-preview"
      >
        {children}
      </PrismicPreviewCore>
    );
  }
  export default PrismicPreviewTest;