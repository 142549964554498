import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

/**
 * Props for `IconsGrid`.
 */
export type IconsGridProps = SliceComponentProps<Content.IconsGridSlice>;

/**
 * Component for "IconsGrid" Slices.
 */
const IconsGrid = ({ slice }: IconsGridProps): JSX.Element => {
  const { tag_text, background_color } = slice.primary;
  const [bg, setBg] = useState(background_color);
  const [active, setActive] = useState(false);

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });

    function isSliceActive() {
      const div = document.getElementById('icons-grid-slice-section');
      const rect = div?.getBoundingClientRect();

      if (rect.top < 94) {
        setActive(true);
      } else {
        setActive(false);
      }
    }

    window.addEventListener('scroll', isSliceActive);

    return () => {
      window.removeEventListener('backgroundColorChange', () => {});
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      id="icons-grid-slice-section"
      data-background={background_color}
      className={clsx(
        'wide-container color-changing-section',
        '[ transition-color ease-in-out duration-1500 ][ w-full ][ relative ]',
        {
          '[ bg-gray-97 ]': bg === 'light',
          '[ bg-gray-0 ]': bg === 'dark',
        },
      )}
    >
      <div className="[ w-full ][ px-5 md:px-16 lg:px-36 pb-8 md:pb-10 lg:pb-24 ]">
        <div className="[ w-full ]">
          <div className="[ flex flex-col ][ w-full lg:w-auto ][ pl-4 sm:pl-6 lg:pl-8 ][ relative ]">
            <div
              className={clsx(
                '[ w-px h-full ][ absolute left-0 ]',
                {
                  '[ bg-gray-60/50 ]': bg === 'light',
                  '[ bg-white/50 ]': bg !== 'light',
                },
              )}
            ></div>

            <div
              className={clsx(
                '[ w-px h-full-less-100 ][ absolute top-25 left-0 z-1000 ]',
                {
                  '[ bg-blue-30 ]': active === true,
                  '[ bg-blue-30/0 ]': active === false,
                },
              )}
            ></div>

            <div
              className={clsx(
                '[ bg-blue-50/10 ][ rounded-lg ][ text-14 font-medium ][ flex justify-center items-center ][ w-fit h-8 ][ my-10 px-3 py-1 ]',
                {
                  '[ text-blue-50 ]': bg === 'light',
                  '[ text-blue-85 ]': bg !== 'light',
                },
              )}>
              {tag_text}
            </div>

            <div className="[ flex flex-wrap gap-6 md:gap-8 ][ w-full ]">
              {slice.items.map(item => (
                <div
                  key={item.heading}
                  className={clsx(
                    '[ rounded-lg ][ flex flex-col justify-between ][ grow-1 flex-[1_1_145px] md:flex-[1_1_206px] lg:flex-[1_1_248px] min-h-28 ][ p-4 ]',
                    {
                      '[ bg-gray-90 ]': bg === 'light',
                      '[ bg-gray-5 ]': bg !== 'light',
                    },
                  )}
                >
                  <PrismicNextImage field={item.icon} className="[ w-8 ][ mb-2 ]" loading='lazy' />

                  <div
                    className={clsx(
                      '[ text-16 font-medium ][ max-w-36 ][ self-stretch ]',
                      {
                        '[ text-gray-0 ]': bg === 'light',
                        '[ text-white ]': bg !== 'light',
                      },
                    )}
                  >
                    {item.heading}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IconsGrid;
