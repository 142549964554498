
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable jsx-a11y/alt-text */
import { asImageSrc, asLink } from '@prismicio/client';
// import { SliceZone } from '@prismicio/react';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { createClient } from 'prismic/prismicio';
// import { components } from 'prismic/slices';
import Hero from 'prismic/slices/Hero';
import IconsGrid from 'prismic/slices/IconsGrid';
import PartnerLogo from 'prismic/slices/PartnerLogo';
import StatsMedia from 'prismic/slices/StatsMedia';
import Media from 'prismic/slices/Media';
import Testimonial from 'prismic/slices/Testimonial';
import VerticalTabs from 'prismic/slices/VerticalTabs';
import OpenGraphCMS from 'ui/components/OpenGraphCMS/OpenGraphCMS';
import { PrismicLocales } from 'ui/config/locales';
import { HeaderService } from 'ui/services/header/header.service';
import { LiveJobService } from '~/service/live-job';
import ResourcesCarousel from 'prismic/slices/ResourcesCarousel';
import ContentFold from 'prismic/slices/ContentFold';
import BottomCta from 'prismic/slices/BottomCta';
const slicesMap = {
    hero: Hero,
    testimonial: Testimonial,
    stats_media: StatsMedia,
    partner_logo: PartnerLogo,
    vertical_tabs: VerticalTabs,
    icons_grid: IconsGrid,
    video_block: Media,
    resources_carousel: ResourcesCarousel,
    content_fold: ContentFold,
    bottom_cta: BottomCta
};
export default function Page({ page }: InferGetStaticPropsType<typeof getStaticProps>) {
    const { meta_title, meta_description, meta_keywords, meta_image, no_index, canonical_url } = page.data;
    // console.log(JSON.stringify(page.data.slices), 'page.data.slices')
    const slices = page.data.slices;
    return (<>
      <OpenGraphCMS SEO={{
            title: meta_title,
            description: meta_description,
            keywords: meta_keywords,
            no_index,
            canonical_url: asLink(canonical_url),
            og_image: { url: asImageSrc(meta_image) }
        }}/>
      {slices.map((slice, index) => {
            const CR: any = slicesMap[slice.slice_type];
            return <CR slice={slice} index={index} slices={slices}/>;
        })}
      {/* <SliceZone slices={page.data.slices} components={components} /> */}
    </>);
}
async function getStaticProps({ previewData, locale, preview, params }: GetStaticPropsContext) {
    const client = createClient({ previewData });
    const developerVideoReviewFields = [
        'developer_video_reviews.name',
        'developer_video_reviews.role',
        'developer_video_reviews.description',
        'developer_video_reviews.video_url',
        'developer_video_reviews.thumbnail',
        'developer_video_reviews.continent',
        'developer_video_reviews.country',
        'developer_video_reviews.country_flag',
    ];
    const developerTestimonialFields = [
        'developer_testimonial.name',
        'developer_testimonial.role',
        'developer_testimonial.country',
        'developer_testimonial.avatar',
        'developer_testimonial.title',
        'developer_testimonial.content',
    ];
    const developerStoryFields = [
        'developer_story.name',
        'developer_story.role',
        'developer_story.title',
        'developer_story.image',
        'developer_story.country',
        'developer_story.flag',
        'developer_story.url',
    ];
    const videoFields = ['video.title', 'video.description', 'video.video_url', 'video.thumbnail', 'video.author'];
    const newsFields = ['news.title', 'news.url', 'news.cover', 'news.logo', 'news.dark_logo', 'news.description', 'news.author', 'news.published_date', 'news.link_text'];
    const blogCopyFields = ['blog_copy.title', 'blog_copy.content', 'blog_copy.image', 'blog_copy.url'];
    const customerTestimonialFields = [
        'customer_testimonial.content',
        'customer_testimonial.author',
        'customer_testimonial.icon',
    ];
    const logoFields = ['logos.logos', 'logos.title', 'logos.description'];
    const hiringStepsFields = ['hiring_steps.steps'];
    const listFields = ['list.list'];
    const documentGroupFields = ['document_group.documents'];
    const hireFields = ['hire.name', 'hire.icon', 'hire.category'];
    const jobFields = ['job.name', 'job.icon', 'job.category'];
    const resourcesFields = ['resources.title', 'resources.description', 'resources.image'];
    const kbFields = ['kb.title', 'kb.description', 'kb.image'];
    const metricsFields = ['metrics.stats'];
    const matchingSkillGroupFields = ['matching_skill_group.skills'];
    const servicesFields = ['service_v2.category_icon', 'service_v2.name', 'service_v2.description'];
    const subScriptionFormFields = [
        'subscription_form.title',
        'subscription_form.description',
        'subscription_form.placeholder_text',
        'subscription_form.cta_text',
        'subscription_form.hubspot_form_id',
    ];
    const blockQuoteFields = ['block_quote.title', 'block_quote.sub_title', 'block_quote.content'];
    const ctaBlockFormFields = [
        'cta_block.title',
        'cta_block.description',
        'cta_block.cta_text',
        'cta_block.cta_link',
        'cta_block.background_image'
    ];
    const shareIconsFields = ['share_icons.icons'];
    const caseStudyV2 = ['case_study_v2.uid', 'case_study_v2.title', 'case_study_v2.description', 'case_study_v2.image'];
    const blogFields = ['blog.uid', 'blog.title', 'blog.image', 'blog.description'];
    // NOTE: We are removing home as temporarily from home page doc for approx. 11 days or so
    const page = await client.getByUID('page_v2', (params?.uid ?? 'home-07242024') as string, {
        fetchLinks: [
            ...logoFields,
            ...developerVideoReviewFields,
            ...developerTestimonialFields,
            ...developerStoryFields,
            ...blogCopyFields,
            ...customerTestimonialFields,
            ...newsFields,
            ...videoFields,
            ...hiringStepsFields,
            ...listFields,
            ...documentGroupFields,
            ...hireFields,
            ...jobFields,
            ...resourcesFields,
            ...kbFields,
            ...metricsFields,
            ...matchingSkillGroupFields,
            ...servicesFields,
            ...subScriptionFormFields,
            ...shareIconsFields,
            ...ctaBlockFormFields,
            ...blockQuoteFields,
            ...caseStudyV2,
            ...blogFields
        ],
        lang: PrismicLocales[locale]
    });
    // TODO: Remove it and use next-common-props
    const headerData = await HeaderService.getHeaderData(previewData);
    await LiveJobService.populateLatestJobsInSlice(page.data.slices, preview);
    return {
        props: { page, headerData }
    };
}

    async function __Next_Translate__getStaticProps__194a32844f5__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194a32844f5__ as getStaticProps }
  