import { asLink } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { SharedSlice } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import clsx from 'clsx';
import { VideoInternal } from 'ui/components/VideoInternal';
import { extractVideoId } from 'ui/components/developer/Video/video.helper';
import { asText } from '@prismicio/client';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { slugify } from 'ui/utils/slugify';
import { useEffect, useState } from 'react';
import { CommonButton } from 'ui/components/CommonButton';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { ContentFoldSliceDynamic1Fr1FrMin768Px } from 'prismic/prismicio-types';

export type ContentFoldDynamic1fr1frMin768pxProps = SliceComponentProps<SharedSlice<'content_fold', ContentFoldSliceDynamic1Fr1FrMin768Px>>;

const ContentFoldDynamic1fr1frMin768px = ({ slice }: ContentFoldDynamic1fr1frMin768pxProps): JSX.Element => {
  const { primary, slice_type, variation } = slice;
  const {
    eyebrow,
    eye_brow_text_color,
    heading,
    heading_text_color,
    description,
    description_text_color,
    image_placement,
    background,
    thumbnail,
    youtube_video_id,
    media_type,
    full_bleed_background_image,
    list_marker_bg_color,
    image
  } = primary;

  const [bg, setBg] = useState();

  const legacy_colors = {
    'hero': 'blue',
    'Primary': 'black',
    'Secondary': 'white',
  };

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);

  return (
    <section
      data-slice-type={slice_type}
      data-slice-variation={variation}
      className={clsx(  
        'ContentFold-Dynamic1fr wide-container color-changing-section',
        '[ transition-color ease-in-out duration-1500 ]',
        {
        'bg-blue-50': background === 'blue' && bg === 'light',
        'bg-gray-97': background === 'light' && bg === 'light', 
        'bg-blue-95': background === 'light_blue' && bg === 'light',
        'bg-gray-0': background === 'dark' || bg === 'dark',
      })}
      data-background={background}
    >
      <div className="[ py-10 px-5 md:px-16 lg:py-24 ]">
        <div
          className={clsx('[ relative ] [ grid grid-cols-1 items-center gap-4 md:grid-cols-1.5-1 lg:gap-24 ]',{
            '[ bg-no-repeat bg-center bg-cover ][ rounded-2xl ][ p-11 lg:p-16 ]': full_bleed_background_image.url,
          })}
        >
          <PrismicNextImage
            field={full_bleed_background_image}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              backgroundPositionX: 'center',
            }}
            className="[ rounded-2xl ]"
            loading="lazy"
          />
          <div className={clsx('[ hidden md:block ][ relative ][ mx-auto ]', {
            'md:order-1' : image_placement === 'right'
          })}>
            {media_type === 'Video' ? (
              <VideoInternal
                data={{
                  video: { id: extractVideoId(youtube_video_id) },
                  thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} loading='lazy' />,
                }}
                playIcon={'/img/icons/icon-play-button.svg'}
                className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
              />
            ) : (
              <PrismicNextImage field={image} className="[ w-full max-w-44 md:max-w-48 lg:max-w-84.75 ][ rounded-2.5xl ][ mx-auto ]" loading='lazy' />
            )}
          </div>
          <div className="z-[1000]">
            <div className="[ md:hidden ][ w-4/5 relative ][ mx-auto ]">
              {full_bleed_background_image.url ? (
                <PrismicNextImage field={image} className="[ w-auto max-w-44 max-h-96 md:max-h-134 md:max-w-48 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" loading='lazy' />
              ) : (
                <>
                  {media_type === 'Video' ? (
                    <VideoInternal
                      data={{
                        video: { id: extractVideoId(youtube_video_id) },
                        thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} loading='lazy' />,
                      }}
                      playIcon={'/img/icons/icon-play-button.svg'}
                      className="[ w-full ][ relative ][ pt-60% ][ [&_img]:rounded-xl ]"
                    />
                  ) : (
                    <PrismicNextImage field={image} className="[ w-auto max-h-96 md:max-h-134 lg:max-h-none ][ rounded-2.5xl ][ mx-auto ]" loading='lazy' />
                  )}
                </>
              )}
            </div>
            {eyebrow && 
              <span className={clsx('[ text-16 font-medium ][ block ][ mb-4 ]', {
                'text-gray-0': eye_brow_text_color === 'primary_black',
                'text-white': eye_brow_text_color === 'primary_white',
                'text-gray-60': eye_brow_text_color === 'secondary_white',
                'text-gray-40': eye_brow_text_color === 'secondary_black'
              })}>{eyebrow}</span>
            }
            {heading && 
              <h2 className={clsx('[ text-30 md:text-48 font-medium leading-tight tracking-tight ][ mb-8 mt-12 ]', {
                'text-gray-0': heading_text_color === 'primary_black',
                'text-white': heading_text_color === 'primary_white',
                'text-gray-60': heading_text_color === 'secondary_white',
                'text-gray-40': heading_text_color === 'secondary_black'
              })}>{heading}</h2>
            }
            {asText(description) && (
              <span
                className={clsx('richtext [ text-16 md:text-18 lg:text-20 md:leading-tight ]', {
                  'text-gray-0': description_text_color === 'primary_black',
                  'text-white': description_text_color === 'primary_white',
                  'text-gray-60': description_text_color === 'secondary_white',
                  'text-gray-40': description_text_color === 'secondary_black',
                  '[&_li]:before:bg-white [&_li]:before:text-gray-0' : list_marker_bg_color === 'light',
                  '[&_li]:before:bg-blue-95 [&_li]:before:text-blue-50' : list_marker_bg_color === 'light_blue',
                  '[&_li]:before:bg-gray-0 [&_li]:before:text-white' : list_marker_bg_color === 'dark',
                })}
              >
                <PrismicRichText field={description} applyStyles={false} />
              </span>
            )}
            {slice.items && slice.items.length > 0 && (
              <div className="[ flex gap-1 ][ mt-8 ]">
                {slice.items.map((button, index) => {
                  const link = asLink(button.button_link);
                  const id = slugify(link || '');
                  return (
                    <CommonButton
                      key={link}
                      color={!button.color_cta_type ? 'blue' : legacy_colors[button.color_cta_type] as any}
                      id={`content-fold-${id}`}
                      field={button.button_link}
                      target={button.target}
                      className="[ w-full md:w-auto ][ group ]"
                    >
                      {button.button_text}
                      {button.button_with_arrow && (
                        <ArrowForward
                          fill="currentColor"
                          className="[ transition-all duration-200 ][ opacity-0 group-hover:opacity-100 ][ w-0 group-hover:w-6 ][ mx-0 group-hover:ml-1 group-hover:-mr-2 ]"
                        />
                      )}
                    </CommonButton>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentFoldDynamic1fr1frMin768px;
