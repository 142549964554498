import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import dynamic from 'next/dynamic';

const sliceVariants = {
  dark: dynamic(() => import('./HeroDark'), { loading: () => <p>Loading...</p> }),
  darkOnlyHomePageWithLogo: dynamic(() => import('./HeroDarkOnlyHomePageWithLogo'), { ssr: true }),
  default: dynamic(() => import('ui/components/Hero/HeroLight'), { loading: () => <p>Loading...</p> }),
  minimal: dynamic(() => import('ui/components/Hero/HeroLight'), { loading: () => <p>Loading...</p> }),
  centered: dynamic(() => import('./HeroCentered'), { loading: () => <p>Loading...</p> }),
  assets: dynamic(() => import('./HeroAssets'), { loading: () => <p>Loading...</p> }),
  blog: dynamic(() => import('./HeroBlog'), { loading: () => <p>Loading...</p> }),
  featureCard: dynamic(() => import('./HeroFeaturedCard'), { loading: () => <p>Loading...</p> }),
  resource: dynamic(() => import('./HeroResources'), { loading: () => <p>Loading...</p> }),
};


/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice, index, slices, context }: HeroProps): JSX.Element => {
  const { variation } = slice;
  const SliceComponent: any = sliceVariants[variation];
  

  return <SliceComponent slice={slice} index={index} slices={slices} context={context}  />;
};
export default Hero;
