import {
  ChangeEventHandler,
  FormEventHandler,
  memo,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import SearchIcon from 'ui/svgs/library/SearchIcon';
import CloseIcon from 'ui/svgs/library/CloseIcon';
import CloseCircleIcon from 'ui/svgs/library/CloseCircleIcon';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { CustomLink } from '../CustomLink';
import SearchResult from '../SearchResult';
import { SearchResponse } from '../../../../apps/web/service/elasticsearch';

type SearchBarProps = {
  defaultValue?: string;
  onSubmit?: (query: string) => void;
  getSuggestions?: (query: string) => Promise<string[]>;
  getQuickResults?: (query: string) => Promise<SearchResponse>;
  className?: string;
};

function SearchBar({ defaultValue, onSubmit, getSuggestions, getQuickResults, className }: SearchBarProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue || '');
  const [results, setResults] = useState<SearchResponse['results']>();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('search');
  const searchData: any = t('.', {}, { returnObjects: true });
  const router = useRouter();

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value);
  };

  const debounceResults = useMemo(() => {
    return debounce(handleChange, 250);
  }, []);

  useEffect(() => {
    return () => {
      debounceResults.cancel();
    };
  });

  useEffect(() => {
    if (value.length >= 3) {
      // getSuggestions?.(value).then(setSuggestions);
      getQuickResults(value).then(response => setResults(response.results));
    } else {
      // setSuggestions([]);
      setResults([]);
    }
  }, [value, getSuggestions, getQuickResults]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (e.currentTarget.q.value) {
      onSubmit?.(e.currentTarget.q.value);
      setOpen(false);
      setValue('');
    }
  };

  return (
    <>
      {!router.pathname.startsWith('/search') && (
        <button
          className={clsx(
            '[ border-0 ][ w-8 h-8 ][ p-1 m-2 lg:m-0 ][ flex items-center justify-center ][ text-white ][ hover:bg-white/10 transition ] [ [&_svg]:pointer-events-none ]',
            {
              '[ bg-white/10 ]': open,
              '[ bg-transparent ]': !open,
            },
            className,
          )}
          onClick={() => {
            startTransition(() => {
              setOpen(open => !open);
            });
          }}
        >
          <SearchIcon fill="#fff" />
        </button>
      )}

      <div
        className={clsx('[ fixed top-[72px] ] left-0 z-[1001] ][ w-full h-full ]', {
          hidden: !open,
        })}
        onClick={() => setOpen(false)}
      >
        <div
          className="wide-container [ min-h-[calc(100vh-72px)] lg:min-h-96 ][ bg-gray-10 lg:bg-gray-0 backdrop-blur-md ]"
          onClick={e => e.stopPropagation()}
        >
          <div className="[ p-5 lg:px-20 ]">
            <button
              className="[ ml-auto py-1 px-3 ][ bg-transparent ][ border-0 lg:border lg:border-solid lg:border-white ][ rounded-lg ][ flex items-center justify-center gap-3 ][ lg:w-12 lg:h-12 ][ hover:bg-white/10 transition ][ text-white  text-14 leading-none font-medium ] [ [&_svg]:pointer-events-none ]"
              onClick={() => {
                setOpen(false);
                setValue('');
              }}
            >
              <CloseIcon fill="#fff" />
              <span className="[ lg:sr-only ]">{searchData.close_button_text}</span>
            </button>
          </div>

          <form className="[ px-5 py-11 lg:px-44 lg:pt-0 lg:pb-16 ]" onSubmit={handleSubmit}>
            <div className="[ border-b border-solid border-gray-30 ][ flex items-center ]">
              <input
                ref={ref}
                className="[ border-0 outline-0 ][ p-2 lg:px-3 ][ w-full ][ text-20 lg:text-28 text-white caret-white ][ bg-transparent ][ focus:placeholder:text-white placeholder:text-white/50 ]"
                name="q"
                autoFocus
                defaultValue={defaultValue}
                onChange={debounceResults}
                autoComplete="off"
                placeholder={searchData.placeholder_text}
              />

              <button
                className="[ w-12 h-12 ][ p-3 ][ bg-transparent ][ hover:bg-white/10 transition ][ border-0 ][ rounded-lg ] [ [&_svg]:pointer-events-none ]"
                type="submit"
                onClick={() => {}}
              >
                <SearchIcon fill="#fff" />
              </button>

              <button
                className="[ w-12 h-12 ][ p-3 ][ bg-transparent ][ hover:bg-white/10 transition ][ border-0 ][ rounded-lg ] [ [&_svg]:pointer-events-none ]"
                type="reset"
                onClick={() => {
                  ref.current?.focus();
                  setValue('');
                }}
              >
                <CloseCircleIcon fill="#fff" />
              </button>
            </div>

            {/* Quick Results */}
            {value.length >= 3 && results.length > 0 && (
              <div className="[ mt-8 md:mt-6 ][ divide-y divide-solid divide-gray-30 ]">
                {results.map(result => (
                  <SearchResult key={result.id} result={result} variant="light" />
                ))}
              </div>
            )}

            {/* Popular searches */}
            {!(value.length >= 3 && results.length > 0) && (
              <div className="[ mt-12 ]">
                <div className="[ text-gray-60 text-14 font-medium ][ mb-5 ]">{searchData.popular_search_title}</div>
                <div className="[ space-y-3 ]">
                  {searchData.popular_searches?.map(({ search_term }, index) => (
                    <Link
                      href={`/search/?q=${encodeURIComponent(search_term)}`}
                      className="[ text-20 lg:text-28 text-white ][ flex space-between items-center ][ rounded-lg ][ py-1 px-3 -mx-3 ][ hover:bg-white/10 transition ]"
                      onClick={() => setOpen(false)}
                      key={index}
                    >
                      <div>{search_term}</div>
                      <ArrowForward className="pointer-events-none" />
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default memo(SearchBar);
