import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { VideoModalButton } from 'ui/components/VideoModalButton';
import { HeadlineProps, HeadlineTag } from './Headline.interface';

export const Headline: FC<HeadlineProps> = props => {
  const {
    title,
    subTitle,
    headlineColumnOneSize,
    headlineSecondColumn,
    watchVideoBtn,
    tag: Tag = HeadlineTag.h2,
    size,
    type,
    className,
    isSubtitleHTML,
    subTitleClassName,
    applySubtitleHTMLClassName,
  } = props;

  const style = useMemo(
    () => (headlineColumnOneSize ? { maxWidth: `${headlineColumnOneSize}px` } : {}),
    [headlineColumnOneSize]
  );

  const subtitleHTMLClassName = useMemo(
    () =>
      clsx(
        '[ [&_p]:nth-of-type-2:mx-0 [&_p]:nth-of-type-2:mt-8 [&_p]:nth-of-type-2:mb-1 [&_p]:nth-of-type-3:mx-0 [&_p]:nth-of-type-3:mt-0 [&_p]:nth-of-type-3:mb-12 ]',
        applySubtitleHTMLClassName
      ),
    [applySubtitleHTMLClassName]
  );

  if (!headlineSecondColumn) {
    if (title || subTitle) {
      return (
        <div
          style={style}
          className="[ [&:not(:last-child)]:mb-7.5 [&:not(:last-child)]:lg:mb-0 ][ [&>*]:last:mb-0 ]"
        >
          {title && (
            <Tag
              className={clsx(
                '[ flex flex-wrap justify-between items-center gap-x-2 gap-y-3 ]',
                {
                  '[ text-28 ]': size === 'md',
                  '[ text-20 md:text-40 ]': size === 'lg',
                  '[ text-gray-10 ]': type === 'normal',
                  '[ text-white ]': type === 'white',
                  '[ bg-clip-text bg-gradient-to-r from-indigo to-blue-60 ][ font-medium text-transparent ]': type === 'gradient',
                },
                className,
              )}
            >
              {typeof title === 'function' ? title() : title}

              {watchVideoBtn && (
                <VideoModalButton
                  watchVideoBtn={watchVideoBtn}
                  className="[ text-14 md:text-16 ][ md:ml-6 p-0 ][ [&_i]:text-14 [&_i]:md:text-20 [&_i]:my-0 [&_i]:ml-0 [&_i]:mr-1.5 [&_i]:md:mr-2 ]"
                />
              )}
            </Tag>
          )}

          {subTitle && (
            <>
              {isSubtitleHTML ? (
                <div
                  className={subtitleHTMLClassName}
                  dangerouslySetInnerHTML={{ __html: subTitle }}
                />
              ) : (
                <div className={subTitleClassName}>{subTitle}</div>
              )}
            </>
          )}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return (
      <>
        <div
          style={style}
          className="[ [&:not(:last-child)]:mb-7.5 [&:not(:last-child)]:lg:mb-0 ][ [&>*]:last:mb-0 ]"
        >
          {title && (
            <Tag
              className={clsx(
                '[ flex flex-wrap justify-between items-center gap-x-2 gap-y-3 ]',
                {
                  '[ text-28 ]': size === 'md',
                  '[ text-20 md:text-40 ]': size === 'lg',
                  '[ text-gray-10 ]': type === 'normal',
                  '[ text-white ]': type === 'white',
                  '[ bg-clip-text bg-gradient-to-r from-indigo to-blue-60 ][ font-medium text-transparent ]': type === 'gradient',
                },
              )}
            >
              {typeof title === 'function' ? title() : title}
            </Tag>
          )}

          {subTitle && (
            <div>{subTitle}</div>
          )}
        </div>
        
        {headlineSecondColumn && (
          <div className="[ [&:not(:last-child)]:mb-7.5 [&:not(:last-child)]:lg:mb-0 ][ [&>*]:last:mb-0 ]">
            {headlineSecondColumn}
          </div>
        )}
      </>
    );
  }
};
