import { Content, asLink } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import clsx from 'clsx';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { NavBarBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import GetStartedModal from 'ui/layout/GetStarted/GetStartedModal';
import { PrismicNextImage } from '@prismicio/next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CommonButton } from 'ui/components/CommonButton';

/**
 * Props for `BottomCta`.
 */
export type BottomCtaProps = SliceComponentProps<Content.BottomCtaSlice>;

/**
 * Component for "BottomCta" Slices.
 */
const BottomCta = ({ slice }: BottomCtaProps): JSX.Element => {
  const [width] = useWindowsSize();
  const [openGetStarted, setOpenGetStarted] = useState(false);
  const router = useRouter();
  const [bg, setBg] = useState('dark');

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);
  
  const btnProps = {
    ...(slice.primary.cta_action === 'Get Started Overlay'
      ? {
          onClick: () => {
            if (width > NavBarBreakpoint) {
              setOpenGetStarted(true);
            } else {
              // redirect
              router.push('/get-started');
            }
          },
        }
      : { href: asLink(slice.primary.cta_url), target: slice.primary.cta_target }),
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background="dark"
      style={{
        backgroundImage:
          width < ResponsiveBreakpoint ? 'none' : `url("${slice.primary.full_bleed_background_image.url}")`,
      }}
      className={clsx(
        'wide-container color-changing-section',
        '[ transition-color duration-1500 ease-in-out ][ bg-gray-0 bg-no-repeat bg-center bg-cover ][ grid grid-cols-1 md:grid-cols-4-1 lg:grid-cols-1.5-1 ][ w-full ]',
      )}    
    >
      <div className="[ px-5 md:px-16 py-16 md:py-28 ]">
        <h2 className="[ font-medium text-30 md:text-48 text-white leading-tight ]">
          {slice.primary.header}
        </h2>

        <PrismicRichText
          field={slice.primary.description}
          applyStyles={true}
          boldHighlight="primary"
          className="[ text-34 leading-tight ][ mb-10 ][ [&_p]:text-16 [&_p]:md:text-18 [&_p]:text-white [&_p]:leading-normal [&_p]:ma-0 ][ !mb-10 ]"
        />
        
        {width < ResponsiveBreakpoint && (
          <div className="[ flex justify-center ][ mb-6 ]">
            <PrismicNextImage field={slice.primary.inline_image} loading='lazy' />
          </div>
        )}

        <CommonButton color="blue" size="medium" {...btnProps} className="[ w-full md:w-auto ]">
          {slice.primary.cta_text}
        </CommonButton>

        <GetStartedModal open={openGetStarted} onClose={() => setOpenGetStarted(false)} />
      </div>
    </section>
  );
};

export default BottomCta;
