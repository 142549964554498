import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

/**
 * Props for `PartnerLogo`.
 */
export type PartnerLogoProps = SliceComponentProps<Content.PartnerLogoSlice>;

/**
 * Component for "PartnerLogo" Slices.
 */
const PartnerLogo = ({ slice }: PartnerLogoProps): JSX.Element => {
  const { items, primary } = slice;
  const { background_color, title } = primary;
  const [bg, setBg] = useState(background_color);

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background={background_color}
      className={clsx(
        'wide-container color-changing-section',
        '[ transition-color ease-in-out duration-1500 ][ w-full ][ w-full ]',
        {
          '[ bg-gray-97 ]': bg === 'light',
          '[ bg-gray-0 ]': bg === 'dark',
        },
      )}
    >
      <div className="[ grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-10% ][ px-5 md:px-16 lg:px-44 py-20 lg:py-24 ]">
        <h2
          className={clsx(
            '[ max-w-80 md:max-w-3xl lg:max-w-full ][ text-30 md:text-56 text-semibold leading-snug tracking-tight ]',
            {
              '[ text-gray-0 ]': bg === 'light',
              '[ text-white ]': bg === 'dark',
            },
          )}
        >
          {title}
        </h2>

        <div className="[ grid gap-4 md:gap-8 grid-cols-160 md:grid-cols-192 ][ h-fit ]">
          {items.map(item => (
            <div
              key={item.image.id}
              className={clsx(
                '[ rounded-lg ][ flex justify-center items-center ][ max-w-48 h-28 ][ px-6 py-3 ]',
                {
                  '[ bg-gray-90 ]': bg === 'light',
                  '[ bg-gray-10 ]': bg === 'dark',
                },
              )}
            >
              <PrismicNextImage
                field={item.image}
                className={clsx(
                  {
                    '[ brightness-0 invert ]': bg === 'dark',
                  },
                )}
                loading='lazy'
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerLogo;
