import * as React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { constants } from 'ui/utils/constants';
import { CustomLink } from 'ui/components/CustomLink';
import ExpandMore from 'ui/svgs/library/ExpandMore';
import HeaderLinkComponent from './HeaderLinkComponent';

export interface NavigationItem {
  label: string;
  description?: string;
  link?: any;
  icon?: { url: string };
  link_target?: string;
}

interface HeaderDropdownProps {
  item: {
    drop_down_type: string;
    navigation_list: {
      data: {
        navigation_links: Array<{
          item_text: string;
          link_to_document: {
            data: {
              navigation_items: NavigationItem[];
            };
          };
        }>;
      };
    };
    resource_post_link?: any;
    resource_title: string;
    resource_read_more_text: string;
    see_all_resources_link: any;
    see_all_resources_text: string;
  };
  text: string;
  id: string;
  handleCloseNav?: () => void;
  onDropDownOpen?: (val: boolean) => void;
}

export const HeaderDropdown = React.memo(({ item, text, id, handleCloseNav, onDropDownOpen }: HeaderDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        // Close if clicked outside dropdown
        (dropdownRef.current && !dropdownRef.current.contains(target)) ||
        // Close if clicked on any anchor tag inside dropdown
        target.tagName.toLowerCase() === 'a'
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // This will handle backdrop in parent component for header element
  React.useEffect(() => {
    onDropDownOpen(isOpen);
  }, [isOpen, onDropDownOpen]);

  const handleLinkClick = React.useCallback(() => {
    setIsOpen(open => !open);
    handleCloseNav();
  }, [handleCloseNav]);

  const getGridColumns = React.useMemo(() => {
    const length = item.navigation_list.data?.navigation_links.length;
    if (length === 2) return 'lg:grid-cols-3';
    if (length === 3) return 'lg:grid-cols-4';
    if (length === 4) return 'lg:grid-cols-5';
    return '';
  }, [item.navigation_list.data?.navigation_links.length]);
  return (
    <div
      ref={dropdownRef}
      className={clsx(
        '',
        '[ border-b border-solid border-gray-40 lg:border-0 ]',
        '[ flex items-center justify-between lg:justify-start flex-col lg:flex-row ]',
        {
          relative: item.drop_down_type !== 'Full Width',
        },
      )}
    >
      <button
        id={id}
        onClick={() => {
          React.startTransition(() => {
            setIsOpen(!isOpen);
          });
        }}
        className={clsx(
          '[ w-full lg:w-fit ]',
          '[ font-normal lg:text-14 xl:text-16 text-white ]',
          '[ flex items-center justify-between lg:justify-start ]',
          '[ px-4 py-5 lg:p-2 ]',
          '[ lg:focus:bg-gray-20 ]',
          '[ lg:hover:bg-gray-20 ]',
          '[ rounded-lg ]',
        )}
      >
        {text}
        <ArrowDown className="hidden lg:block pointer-events-none" />
        <ExpandMore
          className={clsx('lg:hidden pointer-events-none', {
            'rotate-180': isOpen,
          })}
        />
      </button>

      {item.drop_down_type === 'Full Width' ? (
        <div
          className={clsx(
            '[ relative lg:absolute left-0 ]',
            {
              flex: isOpen,
              hidden: !isOpen,
            },
            '[ w-full ] [ lg:px-16 ]',
            '[ bg-gray-20 lg:bg-gray-0/85 lg:backdrop-blur-md ]',
            '[ lg:top-18 lg:z-1001 ][ w-full ][ lg:justify-center ]',
          )}
        >
          <div className={clsx('[ lg:max-w-[1312px] ][ lg:grid lg:gap-8 grid-cols-1 ][ text-white ]', getGridColumns)}>
            {item.navigation_list.data?.navigation_links.map((navLink, index) => {
              return (
                <div className="[ pl-6 mt-8 lg:p-0 lg:my-8 ]" key={index}>
                  <div className="[ border-b border-solid border-gray-30 ][ font-semibold text-white text-14 ][ pb-4 ]">
                    {navLink.item_text}
                  </div>
                  {navLink.link_to_document.data.navigation_items.map((navItem, i) => {
                    return (
                      <React.Fragment key={`dropdown-${navItem.label}-${i}`}>
                        <HeaderLinkComponent
                          withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                          navItem={navItem}
                          isLarge={true}
                          onClick={handleLinkClick}
                        >
                          <>
                            {navItem.icon.url && (
                              <div>
                                <Image
                                  className="[ w-6 h-6 ][ max-w-none ]"
                                  src={navItem.icon.url}
                                  width={24}
                                  height={24}
                                  alt={navItem.label}
                                />
                              </div>
                            )}
                            <div>
                              <div className="[ font-semibold ][ lg:text-14 xl:text-16 ]">{navItem.label}</div>
                              {navItem.description && <p className="[ text-12 mb-0 ]">{navItem.description}</p>}
                            </div>
                          </>
                        </HeaderLinkComponent>
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
            {item.resource_post_link && (
              <div className="[ bg-gray-10 ][ p-8 ]">
                <div className="[ text-12 font-semibold ][ mb-4 ]">{item.resource_title}</div>
                <Image
                  className="[ w-40 h-[105px] object-cover object-center ]"
                  width={160}
                  height={105}
                  src={item.resource_post_link.data.image.url}
                  alt=""
                />
                <div className="[ font-medium ][ mt-2 mb-1 ]">{item.resource_post_link.data.title}</div>
                <p className="[ text-14 mb-2 ]">{item.resource_post_link.data.description}</p>
                <CustomLink href={item.resource_post_link.url}>
                  <a className="[ block mb-4 ][ text-white text-14 underline font-semibold ][ hover:text-blue-50 hover:underline transition ]">
                    <span onClick={handleLinkClick}>{item.resource_read_more_text}</span>
                  </a>
                </CustomLink>
                <CustomLink href={item.see_all_resources_link}>
                  <a className="[ block ][ text-white underline font-semibold ][ hover:text-blue-50 hover:underline transition ]">
                    <span onClick={handleLinkClick}>{item.see_all_resources_text}</span>
                  </a>
                </CustomLink>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            '[ relative w-full lg:w-fit ]',
            {
              flex: isOpen,
              hidden: !isOpen,
            },
            '[ bg-gray-20 lg:bg-gray-0/85 lg:backdrop-blur-md ]',
            '[ lg:fixed lg:top-[72px] lg:z-1001 ][ lg:justify-center ][ pl-6 lg:pl-0 ][ lg:rounded-b-xl ]',
          )}
        >
          <div className="[ max-w-72 ][ pr-2 py-2 lg:p-2 ][ lg:text-14 xl:text-16 ]">
            {item.navigation_list.data?.navigation_links.map((navLink, i) => {
              return (
                <React.Fragment key={`dropdown-${i}`}>
                  {navLink.link_to_document.data.navigation_items.map((navItem, i) => {
                    return (
                      <React.Fragment key={`dropdown-${navItem.label}-${i}`}>
                        <HeaderLinkComponent
                          withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                          navItem={navItem}
                          onClick={handleLinkClick}
                        >
                          <div>
                            <div className="[ font-semibold ][ lg:text-14 xl:text-16 ]">{navItem.label}</div>
                            {navItem.description && <p className="[ text-12 mb-0 ]">{navItem.description}</p>}
                          </div>
                        </HeaderLinkComponent>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
});

HeaderDropdown.displayName = 'HeaderDropdown';

export const ArrowDown = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_905_3305)">
        <path
          d="M8.70834 11.71L11.2983 14.3C11.6883 14.69 12.3183 14.69 12.7083 14.3L15.2983 11.71C15.9283 11.08 15.4783 10 14.5883 10H9.40834C8.51834 10 8.07834 11.08 8.70834 11.71Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_3305">
          <rect width="24" height="24" fill="white" transform="translate(-0.00170898)" />
        </clipPath>
      </defs>
    </svg>
  );
};
