import { SVGProps } from 'react';
export const ExpandLess = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 10.4384L7.92689 14.5115C7.78844 14.65 7.6144 14.7208 7.40479 14.724C7.19519 14.7272 7.01795 14.6564 6.87309 14.5115C6.7282 14.3667 6.65576 14.191 6.65576 13.9846C6.65576 13.7782 6.7282 13.6026 6.87309 13.4577L11.3673 8.96348C11.4609 8.8699 11.5596 8.80387 11.6635 8.76541C11.7673 8.72696 11.8795 8.70773 12 8.70773C12.1205 8.70773 12.2327 8.72696 12.3365 8.76541C12.4404 8.80387 12.5391 8.8699 12.6327 8.96348L17.1269 13.4577C17.2654 13.5962 17.3362 13.7702 17.3394 13.9798C17.3426 14.1894 17.2718 14.3667 17.1269 14.5115C16.982 14.6564 16.8064 14.7288 16.6 14.7288C16.3936 14.7288 16.218 14.6564 16.0731 14.5115L12 10.4384Z"
      fill="white"
    />
  </svg>
);

export default ExpandLess;
