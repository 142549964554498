import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Loader } from 'ui/components/Loader';
import { PreviewBar } from 'ui/components/PreviewBar';
import { Footer } from 'ui/layout/Footer';
// import { Header } from 'ui/layout/HeaderV2';
import { HrefLangTags } from 'ui/utils/generateHrefLang/generateHrefLang';
import Header from '../HeaderV2/HeaderV2';

export interface LayoutProps extends PropsWithChildren {
  headerProps: any;
  footerProps: any;
  triggerCallbackOnAppLevel?: any;
  pathsWhereLocalesAreEnabled?: string[];
  // headerData: any;
}

export const Layout: FC<LayoutProps> = ({
  children,
  headerProps = null,
  footerProps = null,
  triggerCallbackOnAppLevel,
  pathsWhereLocalesAreEnabled,
}) => {
  const { isFallback, pathname } = useRouter();
  const isLocaleEnabledPath = pathsWhereLocalesAreEnabled.includes(pathname); // if the current page is i18n enabled, then add hreflang tags

  return (
    <>
      {isLocaleEnabledPath && <HrefLangTags />}
      {/* <PreviewBar /> */}
      <div className="layout [ flex flex-col ][ w-full ][ pt-18 ]">
        {/* <Header {...headerProps} triggerCallbackOnAppLevel={triggerCallbackOnAppLevel} /> */}
        <Header data={headerProps.headerData} />
        {/* <SubHeader /> */}
        {isFallback ? <Loader /> : children}
        <Footer {...footerProps} />
      </div>
    </>
  );
};

Layout.defaultProps = {
  pathsWhereLocalesAreEnabled: [],
};
