/* eslint-disable react-hooks/rules-of-hooks */
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import dynamic from 'next/dynamic';

const sliceVariants = {
  default: dynamic(() => import('./ContentFoldDefault'), { loading: () => <p>Loading...</p> }),
  steps: dynamic(() => import('./ContentFoldSteps'), { loading: () => <p>Loading...</p> }),
  keyHighlights: dynamic(() => import('./ContentFoldKeyHighlights'), { loading: () => <p>Loading...</p> }),
  dynamic: dynamic(() => import('./ContentFoldDynamic'), { loading: () => <p>Loading...</p> }),
  dynamic1Fr1FrMin768Px: dynamic(() => import('./ContentFoldDynamic1fr1frMin768px'), { ssr: true }),
};


/**
 * Props for `ContentFold`.
 */
export type ContentFoldProps = SliceComponentProps<Content.ContentFoldSlice>;

/**
 * Component for "ContentFold" Slices.
 */
const ContentFold = ({ slice, index, slices, context }: ContentFoldProps): JSX.Element => {
  const { variation } = slice;
  const SliceComponent: any = sliceVariants[variation];
  return <SliceComponent slice={slice} index={index} slices={slices} context={context}  />
};

export default ContentFold;
