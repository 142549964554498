export const ExpandMore = ({className = null, fill = 'white'}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M12 14.6615C11.8795 14.6615 11.7673 14.6423 11.6635 14.6038C11.5596 14.5654 11.4609 14.4993 11.3673 14.4058L6.8731 9.91154C6.73463 9.77309 6.6638 9.59905 6.6606 9.38944C6.65738 9.17982 6.72821 9.00258 6.8731 8.85771C7.01796 8.71284 7.1936 8.64041 7.4 8.64041C7.6064 8.64041 7.78203 8.71284 7.9269 8.85771L12 12.9308L16.0731 8.85771C16.2115 8.71926 16.3856 8.64843 16.5952 8.64521C16.8048 8.64201 16.982 8.71284 17.1269 8.85771C17.2718 9.00258 17.3442 9.17822 17.3442 9.38464C17.3442 9.59104 17.2718 9.76667 17.1269 9.91154L12.6327 14.4058C12.5391 14.4993 12.4404 14.5654 12.3365 14.6038C12.2327 14.6423 12.1205 14.6615 12 14.6615Z"
      fill={fill}
    />
  </svg>
);

export default ExpandMore;
