import { default as NextLink, LinkProps } from 'next/link';
import { FC, PropsWithChildren } from 'react';
import { Locale } from 'ui/config/locales';
import { useClientDefaultLocale } from 'ui/hooks/useClientDefaultLocale';
import clsx from 'clsx';

interface CustomLinkProps extends Omit<LinkProps, 'href'> {
  url?: string;
  href?: string;
  locale?: Locale;
  dataTestId?: string;
  className?: string;
}

export const CustomLink: FC<PropsWithChildren<CustomLinkProps>> = props => {
  const { url, href, locale, children, dataTestId, ...rest } = props;

  const controleHref = url || href || '/';

  const clientDefaultLocale = useClientDefaultLocale(href);

  if( url || href ) {
    return (
      <NextLink
        href={controleHref}
        locale={locale || clientDefaultLocale}
        passHref
        {...rest}
        data-testid={dataTestId}
        legacyBehavior
      >
        {children}
      </NextLink>
    );
  }

  return (
    <span
      className={clsx('[ flex items-center ][ transition-none ][ font-medium ]', props.className)}
      {...rest}
      data-testid={dataTestId}
    >
      {children}
    </span>
  );

};
